import ApiService from './ApiService';
import axios from 'axios';

export default class UserService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getAll(retailerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getById(retailerId, id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users/${id}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async create(retailerId, user) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users`;
        const response = await axios.post(url, user, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async update(retailerId, userId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users/${userId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async delete(retailerId, userId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users/${userId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async activate(retailerId, userId, form) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users/${userId}`;
        const response = await axios.post(url, form, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }
}
import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    title: {
        flexGrow: 1
    },
    container: {
        flex: 1,
        overflowY: 'auto'
    }
});

class SideDrawer extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired
    };

    render() {
        const { classes, title, open, onClose, children, ...rest } = this.props;

        return <Drawer open={open} onClose={onClose} classes={{ paper: classes.paper }} {...rest}>
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={classes.title}>{title}</Typography>
                    <IconButton color="inherit" onClick={onClose}>
                        <ClearIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                {children}
            </div>
        </Drawer>;
    }
}

export default withStyles(styles)(SideDrawer);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { readErrors } from '../../helpers';
import { withStyles } from '@material-ui/core';
import { drawerActionsStyle } from '../../styles';
import { RETAILER_USER_ROLES } from '../../constants';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class UpdateUserForm extends Component {
    static propTypes = {
        callerId: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        const {
            emailAddress,
            firstName,
            lastName,
            role,
            isActive
        } = props.user;

        this.state = {
            emailAddress,
            firstName,
            lastName,
            password: '',
            role,
            isActive,
            errors: {},
            busy: false
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const {
            firstName,
            lastName,
            password,
            role,
            isActive
        } = this.state;

        try {
            this.setState({ busy: true });

            const data = {
                firstName,
                lastName,
                role,
                isActive
            };

            // Only include password if it's been changed.
            if (Boolean(password)) {
                data.password = password;
            }

            await onSave(data);
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                alert(error.response.data);

                this.setState({
                    busy: false
                });
            }
        }
    }

    render() {
        const { classes, callerId, user, onCancel } = this.props;

        const {
            emailAddress,
            firstName,
            lastName,
            password,
            role,
            isActive,
            errors,
            busy
        } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                label="Email address"
                required
                disabled
                margin="normal"
                value={emailAddress}
                helperText={errors.emailAddress}
                error={Boolean(errors.emailAddress)}
                onChange={this.onChange('emailAddress')} />

            <TextField
                autoFocus
                id="firstName"
                label="First name"
                autoComplete="given-name"
                required
                margin="normal"
                value={firstName || ''}
                helperText={errors.firstName}
                error={Boolean(errors.firstName)}
                onChange={this.onChange('firstName')} />

            <TextField
                id="lastName"
                label="Last name"
                autoComplete="family-name"
                required
                margin="normal"
                value={lastName || ''}
                helperText={errors.lastName}
                error={Boolean(errors.lastName)}
                onChange={this.onChange('lastName')} />

            <TextField
                id="password"
                label="Password"
                type="password"
                autoComplete="new-password"
                margin="normal"
                value={password}
                helperText={errors.password}
                error={Boolean(errors.password)}
                onChange={this.onChange('password')} />

            <TextField
                id="role"
                label="Role"
                select
                disabled={callerId === user.id}
                required
                margin="normal"
                value={role}
                onChange={this.onChange('role')}>
                {RETAILER_USER_ROLES.map(role => <MenuItem key={role} value={role}>{role}</MenuItem>)}
            </TextField>

            <FormControl disabled={callerId === user.id}>
                <FormControlLabel control={
                    <Switch color="primary"
                        checked={isActive}
                        onChange={this.onCheckedChange('isActive')} />}
                    label={isActive ? 'Active' : 'Inactive'} />
            </FormControl>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateUserForm);
import ApiService from './ApiService';
import axios from 'axios';

export default class SupportRequestService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async create(supportRequest) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/support-requests`;
        const response = await axios.post(url, supportRequest, { headers });

        return {
            ok: response.status === 202 // This endpoint responds with a 202 Accepted.
        };
    }
}
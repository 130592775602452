import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmbeddedComponent from '../../components/EmbeddedComponent';
import { withStyles } from '@material-ui/core';

const styles = {
    root: {
        flex: 1
    }
}

class Home extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
        reportId: PropTypes.string.isRequired,
        embedUrl: PropTypes.string.isRequired,
        reportService: PropTypes.object.isRequired
    };

    static defaultProps = {
        embedUrl: 'https://app.powerbi.com/reportEmbed'
    };

    state = {
        token: null,
        ready: false
    };

    async componentDidMount() {
        const { retailerId, reportId } = this.props;
        const token = await this.props.reportService.generatePowerBiReportToken(retailerId, reportId);

        this.setState({ token, ready: true });
    }

    onEmbedded(embed) {
        //console.log(`Report embedded: `, embed);
    }

    render() {
        const { classes, reportId, embedUrl } = this.props;
        const { ready, token } = this.state;

        return ready ? <EmbeddedComponent
            className={classes.root}
            embedUrl={embedUrl}
            id={reportId}
            accessToken={token}
            onEmbedded={e => this.onEmbedded(e)} /> :
            'Generating some more awesome, please wait...';
    }
}

export default withStyles(styles)(Home);
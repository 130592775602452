import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SupportRequestForm from './SupportRequestForm';
import { withStyles } from '@material-ui/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { pageTitleStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    content: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        }
    },
    ...pageTitleStyle(theme)
});

class Support extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        supportRequestService: PropTypes.object.isRequired
    };

    async sendSupportRequest(form) {
        const response = await this.props.supportRequestService.create(form);

        if (response.ok) {
            this.props.app.setSnack('Thanks, your request has been received.');
        }

        return response.ok;
    }

    render() {
        const { app, classes, width } = this.props;
        const isMobile = isWidthDown('xs', width);

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classes.pageTitle} gutterBottom variant="h4">Support</Typography>
            </div>
            <Grid container>
                <Grid item xs={12} md={10} lg={9} xl={8}>
                    <Paper className={classes.content} elevation={isMobile ? 0 : 1}>
                        <Typography gutterBottom>
                            Got a question or need some help? Send us the details below and we'll get back to you as soon as we can.
                        </Typography>
                        <SupportRequestForm app={app} onSubmit={form => this.sendSupportRequest(form)} />
                    </Paper>
                </Grid>
            </Grid>
        </div >
    };
}

export default withStyles(styles)(withWidth()(Support));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class BusyButton extends Component {
    static propTypes = {
        busy: PropTypes.bool.isRequired,
        busySize: PropTypes.number
    };

    static defaultProps = {
        busySize: 20
    };

    render() {
        const { busy, busySize, children, disabled, ...rest } = this.props;

        return <Button {...rest} disabled={disabled || busy}>
            {busy && <CircularProgress size={busySize} />}
            {!busy && children}
        </Button>;
    }
}

export function readErrors(response) {
    if (!Boolean(response) || response.status !== 400 || typeof response.data !== 'object') {
        return null;
    }

    const { data } = response;
    const errors = {};

    for (const key in data) {
        errors[key] = data[key][0];
    }

    return errors;
}

import ApiService from './ApiService';
import axios from 'axios';

export default class ReportService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async generatePowerBiReportToken(retailerId, id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/pbi-tokens/${retailerId}/reports/${id}`;
        const response = await axios.post(url, null, { headers });

        return response.data;
    }

    async generatePowerBiDashboardToken(retailerId, id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/pbi-tokens/${retailerId}/dashboards/${id}`;
        const response = await axios.post(url, null, { headers });

        return response.data;
    }
}

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import UsersContainer from '../../containers/UsersContainer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import UserList from './UserList';
import UserTable from './UserTable';
import SideDrawer from '../../components/SideDrawer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateUserForm from './CreateUserForm';
import UpdateUserForm from './UpdateUserForm';
import { Subscribe } from 'unstated';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { fabStyle, pageTitleStyle } from '../../styles';

const styles = theme => ({
    root: {
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    ...pageTitleStyle(theme),
    ...fabStyle(theme)
});

class Users extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
        callerId: PropTypes.string.isRequired,
        userService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.usersContainer = new UsersContainer(props.userService);

        this.onSelectUser = this.onSelectUser.bind(this);
        this.openCreateDrawer = this.openCreateDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    state = {
        drawerTitle: '',
        drawerContent: null,
        ready: false
    };

    async componentDidMount() {
        await this.usersContainer.load(this.props.retailerId);
        this.setState({ ready: true });
    }

    onSelectUser(user) {
        this.props.history.push(`/users/${user.id}`);
    }

    async createUser(form, reload) {
        const ok = await this.usersContainer.create(form, reload);

        if (ok) {
            this.closeDrawer();
        }
    }

    async updateUser(user, form) {
        const ok = await this.usersContainer.update(user.id, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteUser(user) {
        const confirmed = window.confirm(`Delete user ${user.firstName} ${user.lastName}?`);

        if (confirmed) {
            try {
                await this.usersContainer.delete(user.id);
            }
            catch (error) {
                alert(error.response.data);
            }
        }
    }

    openCreateDrawer() {
        const reloadAfterCreate = isWidthUp('sm', this.props.width);

        const drawerContent = <CreateUserForm
            onSave={form => this.createUser(form, reloadAfterCreate)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Create user', drawerContent);
    }

    openUpdateDrawer(user) {
        const drawerContent = <UpdateUserForm
            callerId={this.props.callerId}
            user={user}
            onSave={form => this.updateUser(user, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit user', drawerContent);
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    render() {
        const { classes } = this.props;
        const { drawerTitle, drawerContent, ready } = this.state;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classes.pageTitle} variant="h4">Users</Typography>
            </div>
            <Subscribe to={[this.usersContainer]}>
                {uc => <Fragment>
                    <Hidden smUp implementation="css">
                        <UserList users={uc.state.users}
                            onSelect={u => this.openUpdateDrawer(u)}
                            onDelete={u => this.deleteUser(u)} />
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Paper className={classes.root}>
                            <UserTable users={uc.state.users}
                                onSelect={u => this.openUpdateDrawer(u)}
                                onDelete={u => this.deleteUser(u)} />
                        </Paper>
                    </Hidden>
                    <SideDrawer title={drawerTitle}
                        anchor="right"
                        open={Boolean(drawerContent)}
                        onClose={this.closeDrawer}>
                        {drawerContent}
                    </SideDrawer>
                    {ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateDrawer}>
                        <AddIcon />
                    </Fab>}
                </Fragment>}
            </Subscribe>
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Users)));
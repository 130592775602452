import ApiService from './ApiService';
import axios from 'axios';

export default class PosLinkService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async linkToVend(retailerId, domainPrefix, code) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/pos-links/${retailerId}/vend`;

        const payload = {
            domainPrefix,
            code
        };

        const response = await axios.post(url, payload, { headers });

        return {
            ok: response.status === 201,
            alreadyLinked: response.status === 200
        };
    }
}
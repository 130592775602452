import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import AppContainer from '../containers/AppContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import MainMenu from '../components/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import Routes from '../components/Routes';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const drawerWidth = 270;

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
        width: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    menuButton: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(3)
        }
    },
    hide: {
        display: 'none'
    },
    title: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3)
        }
    }
});

class Layout extends Component {
    static propTypes = {
        config: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.navigate = this.navigate.bind(this);
    }

    state = {
        isOpen: false
    };

    handleDrawerOpen() {
        this.setState({ isOpen: true });
    }

    handleDrawerClose() {
        this.setState({ isOpen: false });
    }

    navigate(item) {
        this.setState({ isOpen: false }, () => this.props.history.push(item.path));
    }

    render() {
        const { config, classes, width } = this.props;
        const { isOpen } = this.state;

        return <Subscribe to={[AppContainer]}>
            {app => <div className={classes.root}>
                <AppBar position="fixed" className={classNames(classes.appBar, {
                    [classes.appBarShift]: isOpen && isWidthUp('sm', width)
                })}>
                    <Toolbar>
                        <IconButton color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            edge="start"
                            className={classNames(classes.menuButton, {
                                [classes.hide]: isOpen && isWidthUp('sm', width)
                            })}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                            {app.state.pageTitle}
                        </Typography>
                        {/* <IconButton color="inherit" onClick={this.openSettings}>
                            <SettingsIcon />
                        </IconButton> */}
                    </Toolbar>
                </AppBar>

                <MainMenu userRole={app.getClaim('role')}
                    isOpen={isOpen}
                    onClick={this.navigate}
                    onClose={this.handleDrawerClose} />

                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Routes config={config} app={app} />
                </main>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={Boolean(app.state.snackContent)}
                    autoHideDuration={5000}
                    onClose={app.handleCloseSnack}
                    ContentProps={{ 'aria-describedby': 'message-id' }}>
                    <SnackbarContent
                        className={classes.success}
                        message={<span id="message-id">{app.state.snackContent}</span>}
                        action={[<IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={app.handleCloseSnack}>
                            <CloseIcon />
                        </IconButton>]}
                    />
                </Snackbar>
            </div>}
        </Subscribe>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Layout)));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import BusyButton from '../../components/BusyButton';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { readErrors } from '../../helpers';
import { actionsStyle } from '../../styles';
import { REQUIRED_NOTIFICATION_PERIODS, OPTIONAL_NOTIFICATION_PERIODS } from '../../constants';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    banner: {
        display: 'flex',
        alignItems: 'center'
    },
    stack: {
        display: 'flex',
        flexDirection: 'column'
    },
    ...actionsStyle(theme)
});

class SettingsForm extends Component {
    static propTypes = {
        notificationsEnabled: PropTypes.bool.isRequired,
        notificationEmailAddress: PropTypes.string.isRequired,
        reportNotificationPeriod: PropTypes.string.isRequired,
        newsNotificationPeriod: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired
    };

    state = {
        errors: {},
        busy: false
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.props.onChange({
            [name]: event.target.value
        });
    }

    onCheckedChange = name => event => {
        this.props.onChange({
            [name]: event.target.checked
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        try {
            this.setState({ busy: true });
            await this.props.onSave();
            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { notificationsEnabled, notificationEmailAddress, reportNotificationPeriod, newsNotificationPeriod, classes, width } = this.props;
        const { errors, busy } = this.state;
        const isMobile = isWidthDown('xs', width);

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <Grid container spacing={isMobile ? 0 : 1}>
                <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="h6">Notifications</Typography>
                    <Typography gutterBottom variant="caption">
                        These settings control how and when we send you email notifications.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={classes.stack}>
                    <FormControl>
                        <FormControlLabel control={
                            <Switch color="primary"
                                checked={notificationsEnabled}
                                onChange={this.onCheckedChange('notificationsEnabled')} />}
                            label={notificationsEnabled ? 'Notifications are enabled' : 'Notifications are disabled'} />
                    </FormControl>
                    <Typography variant="caption" gutterBottom>
                        You will {!notificationsEnabled && 'not '}receive notifications from us. {!notificationsEnabled && 'We may still send you important notifications regarding your account.'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        id="notificationEmailAddress"
                        label="Notification email address"
                        required
                        fullWidth
                        margin="normal"
                        type="email"
                        value={notificationEmailAddress}
                        helperText={errors.notificationEmailAddress}
                        error={Boolean(errors.notificationEmailAddress)}
                        onChange={this.onChange('notificationEmailAddress')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="reportNotificationPeriod"
                        label="Send me my retail performance report"
                        fullWidth
                        margin="normal"
                        select
                        value={reportNotificationPeriod}
                        helperText={errors.reportNotificationPeriod}
                        error={Boolean(errors.reportNotificationPeriod)}
                        onChange={this.onChange('reportNotificationPeriod')}>
                        {REQUIRED_NOTIFICATION_PERIODS.map(nf => <MenuItem key={nf} value={nf}>{nf}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="newsNotificationPeriod"
                        label="Send me retail industry news"
                        fullWidth
                        margin="normal"
                        select
                        value={newsNotificationPeriod}
                        helperText={errors.newsNotificationPeriod}
                        error={Boolean(errors.newsNotificationPeriod)}
                        onChange={this.onChange('newsNotificationPeriod')}>
                        {OPTIONAL_NOTIFICATION_PERIODS.map(nf => <MenuItem key={nf} value={nf}>{nf}</MenuItem>)}
                    </TextField>
                </Grid>
            </Grid>
            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(withWidth()(SettingsForm));
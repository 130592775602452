import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import { actionsColumnStyle, tableRowStyle } from '../styles';

const styles = theme => ({
    ...tableRowStyle(theme),
    ...actionsColumnStyle(theme)
});

class TableView extends Component {
    static propTypes = {
        columns: PropTypes.array.isRequired,
        rows: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        canDelete: PropTypes.func.isRequired
    };

    static defaultProps = {
        canDelete: () => true
    };

    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(event, row) {
        event.stopPropagation();
        this.props.onDelete(row);
    }

    render() {
        const { classes, columns, rows, onSelect, canDelete } = this.props;

        return <Table>
            <TableHead>
                <TableRow>
                    {columns.map(c => <TableCell key={c.name}>{c.name}</TableCell>)}
                    <TableCell className={classes.actionsColumn} />
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(r =>
                    <TableRow key={r.id} className={classes.tableRow} hover onClick={() => onSelect(r)}>
                        {columns.map(c => <TableCell key={c.name}>{c.value(r)}</TableCell>)}
                        <TableCell className={classes.actionsColumn}>
                            {canDelete(r) &&
                                <IconButton onClick={e => this.onDelete(e, r)}>
                                    <DeleteIcon />
                                </IconButton>}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>;
    }
}

export default withStyles(styles)(TableView);
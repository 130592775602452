import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppContainer from '../../containers/AppContainer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PosLinks from './PosLinks';
import SettingsForm from './SettingsForm';
import { withStyles } from '@material-ui/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { pageTitleStyle } from '../../styles';
import { Subscribe } from 'unstated';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    content: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('sm')]: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(2)
            }
        }
    },
    ...pageTitleStyle(theme)
});

class Settings extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
        retailerUserId: PropTypes.string.isRequired,
        vendClientId: PropTypes.string.isRequired,
        vendRedirectUri: PropTypes.string.isRequired,
        settingsService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            notificationsEnabled: false,
            notificationEmailAddress: '',
            reportNotificationPeriod: '',
            newsNotificationPeriod: ''
        };

        this.onSave = this.onSave.bind(this);
    }

    async componentDidMount() {
        const { retailerId, retailerUserId, settingsService } = this.props;
        const response = await settingsService.get(retailerId, retailerUserId);

        const {
            notificationsEnabled,
            notificationEmailAddress,
            reportNotificationPeriod,
            newsNotificationPeriod
        } = response.data;

        if (response.ok) {
            this.setState({
                notificationsEnabled,
                notificationEmailAddress,
                reportNotificationPeriod,
                newsNotificationPeriod
            });
        }
    }

    async onSave(app) {
        const { retailerId, retailerUserId, settingsService } = this.props;

        const {
            notificationsEnabled,
            notificationEmailAddress,
            reportNotificationPeriod,
            newsNotificationPeriod
        } = this.state;

        const response = await settingsService.update(retailerId, retailerUserId, {
            notificationsEnabled,
            notificationEmailAddress,
            reportNotificationPeriod,
            newsNotificationPeriod
        });

        if (response.ok) {
            const {
                notificationsEnabled,
                notificationEmailAddress,
                reportNotificationPeriod,
                newsNotificationPeriod
            } = response.data;

            this.setState({
                notificationsEnabled,
                notificationEmailAddress,
                reportNotificationPeriod,
                newsNotificationPeriod
            });
        }

        if (response.ok) {
            app.setSnack('Your settings have been saved.');
        }
    }

    render() {
        const { classes, width, vendClientId, vendRedirectUri } = this.props;

        const {
            notificationsEnabled,
            notificationEmailAddress,
            reportNotificationPeriod,
            newsNotificationPeriod
        } = this.state;

        const isMobile = isWidthDown('xs', width);

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classes.pageTitle} gutterBottom variant="h4">Settings</Typography>
            </div>
            <Grid container>
                <Grid item xs={12} md={10} lg={9} xl={8}>
                    <Paper className={classes.content} elevation={isMobile ? 0 : 1}>
                        <PosLinks vendClientId={vendClientId} vendRedirectUri={vendRedirectUri} />
                    </Paper>
                    {isMobile && <Divider />}
                    <Paper className={classes.content} elevation={isMobile ? 0 : 1}>
                        <Subscribe to={[AppContainer]}>
                            {ac => <SettingsForm notificationsEnabled={notificationsEnabled}
                                notificationEmailAddress={notificationEmailAddress}
                                reportNotificationPeriod={reportNotificationPeriod}
                                newsNotificationPeriod={newsNotificationPeriod}
                                onChange={changes => this.setState(changes)}
                                onSave={() => this.onSave(ac)} />}
                        </Subscribe>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    };
}

export default withStyles(styles)(withWidth()(Settings));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import BusyButton from '../../components/BusyButton';
import Footer from '../../components/Footer';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { readErrors } from '../../helpers';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    card: {
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        }
    },
    media: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: 70
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center'
    }
});

class SignIn extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        authService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            emailAddress: this.hasEmailAddressOnLocation() ? props.location.state.emailAddress : '',
            password: '',
            showPassword: false,
            errors: {},
            error: null,
            busy: false
        };

        this.onSignIn = this.onSignIn.bind(this);
    }

    hasEmailAddressOnLocation = () => Boolean(this.props.location.state && this.props.location.state.emailAddress);

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSignIn(e) {
        e.preventDefault();
        e.stopPropagation();

        const { authService } = this.props;
        const { emailAddress, password } = this.state;

        try {
            this.setState({ errors: {}, error: null, busy: true });

            const response = await authService.signIn(emailAddress, password);

            if (response.status === 200) {
                const { accessToken, refreshToken } = response.data;
                const { app, history, location } = this.props;
                const { state } = location;
                const from = Boolean(state && state.from) ? state.from : '/';

                app.signIn(accessToken, refreshToken).then(() => history.push(from));
            }
        } catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                this.setState({
                    error: error.response.data,
                    busy: false
                });
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { emailAddress, password, showPassword, errors, error, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSignIn}>
            <Card elevation={3} className={classes.card}>
                <CardMedia className={classes.media} image="/images/mavitra-logo-black.png" title="Mavitra" />
                <CardContent className={classes.content}>
                    <TextField
                        autoFocus={!this.hasEmailAddressOnLocation()}
                        id="emailAddress"
                        label="Email address"
                        autoComplete="username"
                        margin="normal"
                        value={emailAddress}
                        helperText={errors.emailAddress}
                        error={Boolean(errors.emailAddress)}
                        onChange={this.onChange('emailAddress')} />
                    <TextField
                        autoFocus={this.hasEmailAddressOnLocation()}
                        id="password"
                        label="Password"
                        autoComplete="current-password"
                        margin="normal"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        helperText={errors.password}
                        error={Boolean(errors.password)}
                        onChange={this.onChange('password')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showPassword: !showPassword })}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    {Boolean(error) && <Typography align="center" color="error" className={classes.error}>{error}</Typography>}
                </CardContent>
                <CardActions className={classes.actions}>
                    <BusyButton busy={busy} color="primary" variant="contained" type="submit">Sign in</BusyButton>
                </CardActions>
            </Card>
            <Footer />
        </form>;
    }
}

export default withRouter(withStyles(styles)(SignIn));
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import App from './App';
import ConfigService from './services/ConfigService';
import * as serviceWorker from './serviceWorker';

function runApp(config) {
    const app = <Fragment>
        <Helmet titleTemplate="Mavitra - %s" defaultTitle="Mavitra - Retail, Reimagined" />
        <App config={config} />
    </Fragment>;

    ReactDOM.render(app, document.getElementById('root'));
    serviceWorker.unregister();
}

// Load app config then spin up app.
new ConfigService()
    .load('/config.json')
    .then(runApp)
    .catch(error => console.log('Failed to load app config.', error.message));

import { Container } from 'unstated';

export default class RetailerContainer extends Container {
    constructor(userService) {
        super();
        this.userService = userService;
    }

    state = {
        retailerId: null,
        users: []
    };

    async load(retailerId) {
        const response = await this.userService.getAll(retailerId);

        if (response.ok) {
            this.setState({
                retailerId,
                users: response.data
            });
        }

        return response.ok;
    }

    async create(user) {
        const { retailerId } = this.state;
        const response = await this.userService.create(retailerId, user);

        if (response.ok) {
            await this.load(retailerId);
        }

        return response.ok;
    }

    async update(userId, changes) {
        const { retailerId } = this.state;
        const users = this.state.users.slice();
        const user = users.find(u => u.id === userId);

        const response = await this.userService.update(retailerId,
            userId, {
                ...changes,
                timestamp: user.timestamp
            });

        if (response.ok) {
            // Replace old user object with new one.
            const index = users.indexOf(user);
            users.splice(index, 1, response.data);

            this.setState({ users });
        }

        return response.ok;
    }

    async delete(userId) {
        const { retailerId } = this.state;
        const response = await this.userService.delete(retailerId, userId);

        if (response.ok) {
            const users = this.state.users.slice();
            const index = users.findIndex(u => u.id === userId);

            users.splice(index, 1);

            this.setState({ users });
        }
    }
}
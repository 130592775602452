import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import SecureRoute from '../components/SecureRoute';
import Page from '../components/Page';
import Dashboard from '../pages/Dashboard';
import Report from '../pages/Report';
import Users from '../pages/Users';
import Settings from '../pages/Settings';
import Support from '../pages/Support';
import Vend from '../pages/PosLinks/Vend';
import PosLinkService from '../services/PosLinkService';
import ReportService from '../services/ReportService';
import UserService from '../services/UserService';
import SupportRequestService from '../services/SupportRequestService';
import SettingsService from '../services/SettingsService';

export default class Routes extends Component {
    static propTypes = {
        config: PropTypes.object.isRequired,
        app: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        const { config, app } = props;

        this.posLinkService = new PosLinkService(config.apiBaseUrl, () => app.getAccessToken());
        this.reportService = new ReportService(config.apiBaseUrl, () => app.getAccessToken());
        this.settingsService = new SettingsService(config.apiBaseUrl, () => app.getAccessToken());
        this.supportRequestService = new SupportRequestService(config.apiBaseUrl, () => app.getAccessToken());
        this.userService = new UserService(config.apiBaseUrl, () => app.getAccessToken());
    }

    getReportId = state => {
        if (state && state.reportId) {
            return state.reportId;
        }

        return this.props.config.defaultReportId;
    }

    render() {
        const { config, app } = this.props;
        //const { apiBaseUrl, blobStorageBaseUrl } = config;

        return <Switch>
            <SecureRoute exact
                path="/"
                authorised={() => app.hasToken()}
                render={() => <Page title="Dashboard">
                    <Dashboard retailerId={app.getClaim('retailer_id')}
                        dashboardId={config.dashboardId}
                        reportService={this.reportService} />
                </Page>} />

            <SecureRoute exact
                path="/report"
                authorised={() => app.hasToken()}
                render={props => <Page title="Report">
                    <Report retailerId={app.getClaim('retailer_id')}
                        reportId={this.getReportId(props.location.state)}
                        reportService={this.reportService} />
                </Page>} />

            <SecureRoute exact
                path="/users"
                authorised={() => app.hasToken() && app.getClaim('role') === 'Owner'}
                render={() => <Page title="Users">
                    <Users retailerId={app.getClaim('retailer_id')}
                        callerId={app.getClaim('sub')}
                        userService={this.userService} />
                </Page>} />

            <SecureRoute exact
                path="/settings"
                authorised={() => app.hasToken() && app.getClaim('role') === 'Owner'}
                render={() => <Page title="Settings">
                    <Settings retailerId={app.getClaim('retailer_id')}
                        retailerUserId={app.getClaim('sub')}
                        vendClientId={config.vendClientId}
                        vendRedirectUri={config.vendRedirectUri}
                        settingsService={this.settingsService} />
                </Page>} />

            <SecureRoute exact
                path="/support"
                authorised={() => app.hasToken()}
                render={() => <Page title="Support">
                    <Support app={app} supportRequestService={this.supportRequestService} />
                </Page>} />

            <SecureRoute exact
                path="/link-to-vend"
                authorised={() => app.hasToken()}
                render={() => <Page title="Link to Vend">
                    <Vend retailerId={app.getClaim('retailer_id')}
                        posLinkService={this.posLinkService} />
                </Page>} />

            <Redirect to="/not-found" />
        </Switch>;
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import BusyButton from '../../components/BusyButton';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { readErrors } from '../../helpers';
import { actionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    banner: {
        display: 'flex',
        alignItems: 'center'
    },
    ...actionsStyle(theme)
});

const REQUEST_TYPES = [
    'Account',
    'Billing',
    'Bug report',
    'Other'
];

class SupportRequestForm extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        firstName: this.props.app.getClaim('given_name', ''),
        lastName: this.props.app.getClaim('family_name', ''),
        emailAddress: this.props.app.getClaim('email', ''),
        phoneNumber: '',
        requestType: REQUEST_TYPES[0],
        message: '',
        errors: {},
        busy: false
    });

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { firstName, lastName, emailAddress, phoneNumber, requestType, message } = this.state;

        try {
            this.setState({ busy: true });

            const data = {
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                requestType,
                message
            };

            const sent = await this.props.onSubmit(data);

            if (sent) {
                this.setState(this.getInitialState());
            }
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { classes, width } = this.props;
        const { firstName, lastName, emailAddress, phoneNumber, requestType, message, errors, busy } = this.state;
        const isMobile = isWidthDown('xs', width);

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <Grid container spacing={isMobile ? 0 : 1}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="firstName"
                        label="First name"
                        required
                        fullWidth
                        autoFocus
                        margin="normal"
                        value={firstName}
                        helperText={errors.firstName}
                        error={Boolean(errors.firstName)}
                        onChange={this.onChange('firstName')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="lastName"
                        label="Last name"
                        required
                        fullWidth
                        margin="normal"
                        value={lastName}
                        helperText={errors.lastName}
                        error={Boolean(errors.lastName)}
                        onChange={this.onChange('lastName')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="emailAddress"
                        label="Email address"
                        required
                        fullWidth
                        margin="normal"
                        type="email"
                        value={emailAddress}
                        helperText={errors.emailAddress}
                        error={Boolean(errors.emailAddress)}
                        onChange={this.onChange('emailAddress')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="phoneNumber"
                        label="Phone number"
                        fullWidth
                        margin="normal"
                        type="tel"
                        value={phoneNumber}
                        helperText={errors.phoneNumber}
                        error={Boolean(errors.phoneNumber)}
                        onChange={this.onChange('phoneNumber')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="requestType"
                        label="Request type"
                        fullWidth
                        margin="normal"
                        select
                        value={requestType}
                        helperText={errors.requestType}
                        error={Boolean(errors.requestType)}
                        onChange={this.onChange('requestType')}>
                        {REQUEST_TYPES.map(rt => <MenuItem key={rt} value={rt}>{rt}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="message"
                        label="Message"
                        required
                        fullWidth
                        margin="normal"
                        multiline
                        rows={5}
                        value={message}
                        helperText={errors.message}
                        error={Boolean(errors.message)}
                        onChange={this.onChange('message')} />
                </Grid>
            </Grid>
            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Send</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(withWidth()(SupportRequestForm));
import axios from 'axios';

export default class AuthService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    setBaseUrl(baseUrl) {
        this.baseUrl = baseUrl;
    }

    signIn(emailAddress, password) {
        const headers = { 'Content-Type': 'application/json' };
        return axios.post(`${this.baseUrl}/auth`, { emailAddress, password }, { headers });
    }

    refreshToken(accessToken, refreshToken) {
        const headers = { 'Content-Type': 'application/json' };
        return axios.post(`${this.baseUrl}/auth/refresh`, { accessToken, refreshToken }, { headers });
    }
}
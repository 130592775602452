import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'unstated';
import Page from './components/Page';
import AppContainer from './containers/AppContainer';
import AuthService from './services/AuthService';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Switch } from 'react-router-dom';
import Layout from './components/Layout';
import Activate from './pages/Activation';
import SignIn from './pages/SignIn';
import NotFound from './pages/NotFound';
import UserService from './services/UserService';
import axios from 'axios';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#673ab7'
    },
    secondary: {
      main: '#424242'
    }
  }
});

const history = createBrowserHistory();

export default class App extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.appContainer = new AppContainer();

    this.authService = new AuthService(props.config.apiBaseUrl);
    this.userService = new UserService(props.config.apiBaseUrl, () => this.appContainer.getAccessToken());

    // Response error interceptor.
    axios.interceptors.response.use(null, error => this.handleErrorResponse(error));
  }

  handleErrorResponse(error) {
    const { response } = error;
    const { status } = response;

    switch (status) {
      case 401:
        return this.handleUnauthorizedResponse(response);
      case 429:
        return this.handleTooManyRequestsResponse(response);
      default:
        return Promise.reject(error);
    }
  }

  async handleUnauthorizedResponse(response) {
    const { config, headers } = response;
    const { apiBaseUrl } = this.props.config;

    const tokenNeedsRefreshing = headers['token-expired'] &&
      !config._isRetry && // Not a retry request.
      config.url !== apiBaseUrl + '/auth/refresh'; // Not a refresh request.

    if (tokenNeedsRefreshing) {
      let resp;

      if (this.refresh) {
        // Refresh request already in flight. Wait until it's done then we'll retry this request.
        resp = await this.refresh;
      } else {
        const { accessToken, refreshToken } = this.appContainer.state;

        try {
          this.refresh = this.authService.refreshToken(accessToken, refreshToken);
          resp = await this.refresh;
        } catch {
          // Failed to refresh token. Force sign out.
          config.headers.Authorization = null;
          await this.appContainer.signOut();
          return axios(config);
        } finally {
          this.refresh = null;
        }

        await this.appContainer.signIn(resp.data.accessToken, resp.data.refreshToken);
      }

      // Retry original request with new access token.
      config._isRetry = true;
      config.headers.Authorization = `Bearer ${resp.data.accessToken}`;

      return axios(config);
    } else {
      await this.appContainer.signOut();

      // Token couldn't be refreshed. Allow pending state transitions to settle then redirect to sign in.
      setTimeout(() => history.push({
        pathname: '/sign-in',
        state: {
          from: history.location
        }
      }));
    }
  }

  handleTooManyRequestsResponse(response) {
    const { config } = response;

    config._isRetry = true;

    // Begin retry attempts with exponential backoff.
    if (!config._wait) {
      config._retryAttempt = 1;
      config._wait = 1000;
    } else {
      config._retryAttempt++;
      config._wait *= 2;
    }

    return new Promise(res => setTimeout(() => res(axios(config)), config._wait));
  }

  render() {
    const { config } = this.props;

    const routes = <Switch>
      <Route exact
        path="/activate/:retailerId/:userId/:token"
        render={() => <Page title="Activate your account">
          <Activate userService={this.userService} />
        </Page>} />

      <Route exact
        path="/sign-in"
        render={() => <Page title="Sign in">
          <SignIn app={this.appContainer} authService={this.authService} />
        </Page>} />

      <Route exact path='/not-found' component={NotFound} />

      <Route render={() => <Layout config={config} />} />
    </Switch>;

    return <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Provider inject={[this.appContainer]}>
        <Router history={history}>
          {routes}
        </Router>
      </Provider>
    </MuiThemeProvider>;
  }
}
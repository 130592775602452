import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import SignOut from './SignOut';
import { withStyles } from '@material-ui/core';
import menu from '../menu';

const drawerWidth = 270;

const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    }
});

class Menu extends Component {
    static propTypes = {
        userRole: PropTypes.string,
        isOpen: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired
    };

    render() {
        const { classes, userRole, isOpen, onClick, onClose } = this.props;

        const list = <div>
            <List>
                {menu.map((item, index) => item.divider ?
                    <Divider key={index} /> : !Boolean(item.roles) || (Boolean(userRole) && item.roles.includes(userRole)) ?
                        <ListItem key={index} button title={item.name} onClick={_ => onClick(item)}>
                            <ListItemIcon>
                                <Icon>{item.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem> : null)}
                <Divider />
                <SignOut />
            </List>
        </div>;

        return <Fragment>
            <Hidden mdUp>
                <Drawer variant="temporary"
                    open={isOpen}
                    onClose={onClose}
                    classes={{
                        paper: classes.drawer
                    }}
                    ModalProps={{ keepMounted: true }}>
                    {list}
                </Drawer>
            </Hidden>
            <Hidden smDown>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: isOpen,
                        [classes.drawerClose]: !isOpen,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: isOpen,
                            [classes.drawerClose]: !isOpen,
                        }),
                    }}
                    open={isOpen}                >
                    <div className={classes.toolbar} >
                        <IconButton onClick={onClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    {list}
                </Drawer>
            </Hidden>
        </Fragment>;
    }
}

export default withStyles(styles)(Menu);
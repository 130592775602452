import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListView from '../../components/ListView';

export default class UserList extends Component {
    static propTypes = {
        users: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { users, onSelect, onDelete } = this.props;

        return <ListView items={users}
            label={u => u.emailAddress}
            caption={u => `${u.firstName || ''} ${u.lastName || ''}`}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}

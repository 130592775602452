import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import BusyButton from '../../components/BusyButton';
import Footer from '../../components/Footer';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Done from '@material-ui/icons/Done';
import { green } from '@material-ui/core/colors';
import { readErrors } from '../../helpers';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    card: {
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        }
    },
    media: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: 70
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center'
    },
    done: {
        color: green[500],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    tick: {
        fontSize: 40,
        marginBottom: theme.spacing(1)
    }
});

class Activate extends Component {
    static propTypes = {
        userService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.onActivate = this.onActivate.bind(this);
    }

    state = {
        firstName: '',
        lastName: '',
        password: '',
        showPassword: false,
        activated: false,
        errors: {},
        error: null,
        busy: false
    };

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    navigateToSignIn(emailAddress, navigateTimeout = 3000) {
        setTimeout(() => this.props.history.push({
            pathname: '/sign-in',
            state: {
                emailAddress
            }
        }), navigateTimeout);
    }

    async onActivate(e) {
        e.preventDefault();
        e.stopPropagation();

        const { userService, match } = this.props;
        const { firstName, lastName, password } = this.state;
        const { retailerId, userId, token } = match.params;

        try {
            this.setState({ errors: {}, busy: true });

            const form = {
                firstName,
                lastName,
                password,
                token
            };

            const response = await userService.activate(retailerId, userId, form);

            if (response.ok) {
                this.setState({ activated: true }, () => this.navigateToSignIn(response.data.emailAddress));
            }
        } catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                this.setState({
                    error: error.response.data,
                    busy: false
                });
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { firstName, lastName, password, showPassword, activated, errors, error, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onActivate}>
            <Card elevation={3} className={classes.card}>
                <CardMedia className={classes.media} image="/images/mavitra-logo-black.png" title="Mavitra" />
                <CardContent className={classes.content}>
                    <TextField
                        autoFocus
                        id="firstName"
                        label="First name"
                        autoComplete="given-name"
                        disabled={activated}
                        required
                        margin="normal"
                        value={firstName}
                        helperText={errors.firstName}
                        error={Boolean(errors.firstName)}
                        onChange={this.onChange('firstName')} />
                    <TextField
                        id="lastName"
                        label="Last name"
                        autoComplete="family-name"
                        disabled={activated}
                        required
                        margin="normal"
                        value={lastName}
                        helperText={errors.lastName}
                        error={Boolean(errors.lastName)}
                        onChange={this.onChange('lastName')} />
                    <TextField
                        id="password"
                        label="Password"
                        autoComplete="new-password"
                        disabled={activated}
                        required
                        margin="normal"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        helperText={errors.password}
                        error={Boolean(errors.password)}
                        onChange={this.onChange('password')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showPassword: !showPassword })}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    {Boolean(errors.token || error) && <Typography align="center" color="error">
                        {errors.token || error}
                    </Typography>}
                </CardContent>
                {!activated && <CardActions className={classes.actions}>
                    <BusyButton busy={busy} color="primary" variant="contained" type="submit">Activate my account</BusyButton>
                </CardActions>}
                {activated && <div className={classes.done}>
                    <Done className={classes.tick} />
                    <Typography color="inherit">Your account is now ready to use. Let's get started!</Typography>
                </div>}
            </Card>
            <Footer />
        </form>;
    }
}

export default withRouter(withStyles(styles)(Activate));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmbeddedComponent from '../../components/EmbeddedComponent';
import parseUrl from 'url-parse';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const styles = {
    root: {
        flex: 1,
        '& iframe': {
            border: 'none'
        }
    }
};

class Dashboard extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
        dashboardId: PropTypes.string.isRequired,
        embedUrl: PropTypes.string.isRequired,
        reportService: PropTypes.object.isRequired
    };

    static defaultProps = {
        embedUrl: 'https://app.powerbi.com/dashboardEmbed'
    };

    state = {
        token: null,
        ready: false
    };

    async componentDidMount() {
        const { retailerId, dashboardId } = this.props;
        const token = await this.props.reportService.generatePowerBiDashboardToken(retailerId, dashboardId);

        this.setState({ token, ready: true });
    }

    onEmbedded(embed) {
        //console.log(`Dashboard embedded: `, embed);
    }

    onTileClick(e) {
        const { reportEmbedUrl } = e.detail;

        if (Boolean(reportEmbedUrl)) {
            const { reportId } = parseUrl(reportEmbedUrl, true).query;

            this.props.history.push({
                pathname: '/report',
                state: {
                    reportId
                }
            });
        }
    }

    render() {
        const { classes, dashboardId, embedUrl } = this.props;
        const { ready, token } = this.state;

        return ready ? <EmbeddedComponent
            type="dashboard"
            className={classes.root}
            embedUrl={embedUrl}
            id={dashboardId}
            accessToken={token}
            onEmbedded={e => this.onEmbedded(e)}
            onTileClick={e => this.onTileClick(e)} /> :
            'Generating awesome, please wait...';
    }
}

export default withRouter(withStyles(styles)(Dashboard));
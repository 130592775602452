import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as pbi from 'powerbi-client';

class EmbeddedComponent extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        embedUrl: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        accessToken: PropTypes.string.isRequired,
        onEmbedded: PropTypes.func,
        onTileClick: PropTypes.func
    }

    static defaultProps = {
        type: 'report'
    };

    componentDidMount() {
        const { type, embedUrl, id, accessToken, onEmbedded, onTileClick } = this.props;

        var config = {
            type,
            id,
            embedUrl,
            tokenType: pbi.models.TokenType.Embed,
            accessToken
        };

        this.component = window.powerbi.embed(this.containerElement, config);

        if (Boolean(onEmbedded)) {
            onEmbedded(this.component);
        }

        if (Boolean(onTileClick)) {
            this.component.on('tileClicked', onTileClick);
        }
    }

    componentWillUnmount() {
        window.powerbi.reset(this.containerElement);
    }

    render() {
        const { children, embedUrl, id, accessToken, onEmbedded, onTileClick, ...rest } = this.props;
        
        return <div {...rest} ref={el => this.containerElement = el}>
            {children}
        </div>;
    }
}

export default EmbeddedComponent;
import { Container } from 'unstated';
import storage from 'local-storage-fallback';

const DEFAULT_PAGE_TITLE = 'Mavitra Retailer Portal';

export default class AppContainer extends Container {
    constructor() {
        super();
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            accessToken: storage.getItem('AccessToken'),
            refreshToken: storage.getItem('RefreshToken'),
            pageTitle: DEFAULT_PAGE_TITLE,
            snackContent: null
        };
    }

    setPageTitle(title) {
        this.setState({ pageTitle: title || DEFAULT_PAGE_TITLE });
    }

    hasToken = () => Boolean(this.state.accessToken);

    getAccessToken = () => this.state.accessToken;

    getClaim(name, defaultValue) {
        const accessToken = this.getAccessToken();

        if (!Boolean(accessToken)) {
            // We don't have any stored tokens.
            return defaultValue || null;
        }

        const claims = JSON.parse(atob(accessToken.split('.')[1]));

        // return the value of the claim, or undefined if the claim does not exist
        return claims[name] || defaultValue || null;
    }

    signIn = (accessToken, refreshToken) => {
        storage.setItem('AccessToken', accessToken);
        storage.setItem('RefreshToken', refreshToken);

        return this.setState({ accessToken, refreshToken });
    };

    signOut = () => {
        storage.removeItem('AccessToken');
        storage.removeItem('RefreshToken');

        // Reset state.
        return this.setState(this.getInitialState());
    };

    setSnack = (content = null) => this.setState({ snackContent: content });

    handleCloseSnack = () => this.setSnack();
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { readErrors } from '../../helpers';
import { withStyles } from '@material-ui/core';
import { drawerActionsStyle } from '../../styles';
import { RETAILER_USER_ROLES } from '../../constants';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateUserForm extends Component {
    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        emailAddress: '',
        role: RETAILER_USER_ROLES[0],
        errors: {},
        busy: false
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { emailAddress, role } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                emailAddress,
                role
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, onCancel } = this.props;
        const { emailAddress, role, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="emailAddress"
                label="Email address"
                required
                margin="normal"
                value={emailAddress}
                helperText={errors.emailAddress}
                error={Boolean(errors.emailAddress)}
                onChange={this.onChange('emailAddress')} />

            <TextField
                id="role"
                label="Role"
                select
                required
                margin="normal"
                value={role}
                onChange={this.onChange('role')}>
                {RETAILER_USER_ROLES.map(role => <MenuItem key={role} value={role}>{role}</MenuItem>)}
            </TextField>
    
            <div className={classes.drawerActions}>
                    <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                    <Button onClick={onCancel}>Cancel</Button>
                </div>
        </form>;
    }
}
    
export default withStyles(styles)(CreateUserForm);
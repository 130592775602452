import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { readErrors } from '../../helpers';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    vendorLogo: {
        maxHeight: 100,
    }
});

class PosLinks extends Component {
    static propTypes = {
        vendClientId: PropTypes.string.isRequired,
        vendRedirectUri: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.props.onChange({
            [name]: event.target.value
        });
    }

    onCheckedChange = name => event => {
        this.props.onChange({
            [name]: event.target.checked
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        try {
            this.setState({ busy: true });
            await this.props.onSave();
            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { classes, width, vendClientId, vendRedirectUri } = this.props;
        const isMobile = isWidthDown('xs', width);

        return <div className={classes.root}>
            <Grid container spacing={isMobile ? 0 : 1}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">Point of sale links</Typography>
                    <Typography gutterBottom variant="caption">
                        Link Mavitra to your point of sale system so people can easily find all of your products.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <a href={`https://secure.vendhq.com/connect?response_type=code&client_id=${vendClientId}&redirect_uri=${vendRedirectUri}`} title="Link to Vend">
                        <img className={classes.vendorLogo} src="/images/vend-logo.png" alt="Vend logo" />
                    </a>
                </Grid>
            </Grid>
        </div>;
    }
}

export default withStyles(styles)(withWidth()(PosLinks));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../components/TableView';

export default class UserTable extends Component {
    static propTypes = {
        users: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { users, onSelect, onDelete } = this.props;

        const columns = [{
            name: 'Email address',
            value: u => u.emailAddress
        }, {
            name: 'First name',
            value: u => u.firstName
        }, {
            name: 'Last name',
            value: u => u.lastName
        }, {
            name: 'Role',
            value: u => u.role
        }, {
            name: 'Status',
            value: u => u.isActive ? 'Active' : 'Inactive'
        }];

        return <TableView columns={columns}
            rows={users}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}
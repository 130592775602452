import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { pageTitleStyle } from '../../styles';
import queryString from 'query-string';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    content: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        }
    },
    busy: {
        display: 'flex',
        alignItems: 'center'
    },
    progress: {
        marginRight: theme.spacing(2)
    },
    ...pageTitleStyle(theme)
});

class Vend extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
        posLinkService: PropTypes.object.isRequired
    };

    state = {
        busy: true,
        alreadyLinked: false,
        error: false
    };

    async componentDidMount() {
        const { location } = this.props;
        const params = queryString.parse(location.search);

        if (!params.domain_prefix || !params.code || !!params.error) {
            this.setState({ busy: false, error: true });
        } else {
            await this.linkToVend(params.domain_prefix, params.code);
        }
    }

    async linkToVend(domainPrefix, code) {
        const { retailerId, posLinkService } = this.props;

        try {
            const response = await posLinkService.linkToVend(retailerId, domainPrefix, code);

            if (response.ok) {
                this.setState({ busy: false });
            } else if (response.alreadyLinked) {
                this.setState({
                    busy: false,
                    alreadyLinked: true
                });
            } else {
                this.setState({
                    busy: false,
                    error: true
                });
            }
        } catch {
            this.setState({
                busy: false,
                error: true
            });
        }
    }

    render() {
        const { classes, width } = this.props;
        const { busy, alreadyLinked, error } = this.state;
        const isMobile = isWidthDown('xs', width);

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classes.pageTitle} gutterBottom variant="h4">Link to Vend</Typography>
            </div>
            <Grid container>
                <Grid item xs={12} md={10} lg={9} xl={8}>
                    <Paper className={classes.content} elevation={isMobile ? 0 : 1}>
                        {busy && <div className={classes.busy}>
                            <CircularProgress size={20} className={classes.progress} />
                            <Typography>Please wait...</Typography>
                        </div>}
                        {error && <Typography>Sorry, we were unable to link your Vend account. Please try again later.</Typography>}
                        {!busy && !alreadyLinked && !error && <Typography>Your account is now linked to Vend.</Typography>}
                        {alreadyLinked && <Typography>Your account is already linked to Vend.</Typography>}
                    </Paper>
                </Grid>
            </Grid>
        </div >
    };
}

export default withRouter(withStyles(styles)(withWidth()(Vend)));